<template>
  <v-row class="elevation-form px-5 py-5">
    <v-col cols="12">
      <h3>{{ router.title }}</h3>
    </v-col>
    <v-col cols="12" class="py-0 px-0 mx-0" v-for="(item, index) in router.router" :key="index">
      <v-divider v-if="id % 2 != 0"></v-divider>
      <v-row>
        <v-col cols="6" md="4">
          <h3 class="router-title" @click="$router.push({ name: `${item.router}` })">{{ item.title }}</h3>
        </v-col>
        <v-col cols="6" md="8">
          {{ item.des }}
          <span v-if="item.subtitle">
            <span>"</span>
            <span class="router-title" @click="$router.push({ name: `${item.router}` })">
              {{ item.subtitle }}
            </span>
            <span>"</span>
          </span>
        </v-col>
      </v-row>
      <v-divider v-if="id % 2 === 0 && index + 1 < router.router.length"></v-divider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    router: Object,
    id: Number,
  },
};
</script>
<style lang="scss" scoped>
.router-title {
  color: #0061af;
}
.router-title:hover {
  cursor: pointer;
}
</style>
